<template>
  <v-dialog
    :persistent="false"
    v-model="active"
    @keydown.esc="emitClose"
    @click:outside="emitClose"
    width="auto"
    max-width="700px"
    class="overflow-overlay"
  >
    <v-card id="gene-result-dialog" class="pa-0 rounded-lg">
      <v-card-title class="solid-heading rounded-t-lg my-0 py-6 px-2 px-sm-10">
        <div class="card-title text-h3 font-weight-semibold white--text mt-2">
          {{ geneData.gene.symbol }} ({{ geneData.result.result }})
          <v-icon :color="geneData.result.label" large>mdi-circle</v-icon>
        </div>
        <v-card-subtitle class="white--text text-h4 py-0 font-weight-light">
          {{ geneData.gene.name[$i18n.locale] }}
        </v-card-subtitle>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-container style="background: #381273" class="py-8" fluid>
          <v-row justify="space-between" class="px-3 px-sm-8">
            <div class="my-1 white--text">
              <strong class="font-weight-semibold accent--text"
                >PHARMGKB:</strong
              >
              {{ geneData.gene.pharm_gkb }}
            </div>
            <div class="my-1 white--text">
              <strong class="font-weight-semibold accent--text">OMIM:</strong>
              {{ geneData.gene.omim }}
            </div>
            <div class="my-1 white--text">
              <strong class="font-weight-semibold accent--text">LOCUS:</strong>
              {{ geneData.gene.locus }}
            </div>
            <div class="my-1 white--text">
              <strong class="font-weight-semibold accent--text"
                >dbSNP ID:</strong
              >
              {{ geneData.gene.data_matrix_key.replace("*null", "") }}
            </div>
            <div class="my-1 white--text">
              <strong class="font-weight-semibold accent--text">ALIAS:</strong>
              {{ geneData.gene.alternative_symbols.join(", ") }}
            </div>
          </v-row>
        </v-container>

        <v-row
          v-if="geneData.gene.haplotype_components"
          class="px-4 px-sm-8 pt-6"
        >
          <v-chip
            v-for="c in geneData.gene.haplotype_components.split('|')"
            :key="c"
            class="ma-2 font-weight-semibold"
            color="primary"
            text-color="darkprimary"
            outlined
          >
            {{ c.split("*")[1] }}
            <span right class="font-weight-bold primary--text ml-2">
              {{ gxResultsDict[c] }}
            </span>
          </v-chip>
        </v-row>
        <v-divider></v-divider>

        <v-row class="px-8 mt-10">
          <div class="px-0 px-sm-4">
            <div class="left-border mb-2 pl-2 pt-1">
              <strong class="mr-2">{{ $t("Fenotipo") }}: </strong>
              {{
                geneData.gene.texts[geneData.result.label][$i18n.locale]
                  .phenotype
              }}
            </div>
            <div class="left-border mb-2 pl-2 pt-1">
              <strong class="mr-2">{{ $t("Actividad") }}: </strong>
              {{
                geneData.gene.texts[geneData.result.label][$i18n.locale]
                  .activity
              }}
            </div>
          </div>
        </v-row>

        <v-divider></v-divider>

        <v-row class="px-8">
          <p class="text-justify">
            {{
              geneData.gene.summary[$i18n.locale] || geneData.gene.summary.en
            }}
          </p>
        </v-row>

        <ExpandableCardSection
          :title="$t('related drugs')"
          :content="relatedDrugs"
        />
      </v-card-text>

      <v-card-actions class="d-flex justify-space-around py-6 mt-8">
        <v-container>
          <v-row justify="space-around">
            <v-btn color="darkprimary" rounded depressed @click="emitClose">
              {{ $t("Cerrar") }}
            </v-btn>
            <v-btn
              color="primary"
              elevation=""
              rounded
              depressed
              @click="
                $router.push({
                  name: 'Explorer.gene_detail',
                  params: { id: geneData.geneId }
                })
              "
            >
              {{ $t("read_more") }}
            </v-btn>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import ExpandableCardSection from "@/components/mylogy/ExpandableCardSection.vue";

export default {
  name: "GeneResultDialog",
  components: { ExpandableCardSection },
  props: ["active", "geneData"],
  computed: {
    ...mapGetters("gxResults", ["gxResultsDict"]),
    ...mapGetters("pgxGenes", ["pgxGeneSubs"]),
    ...mapGetters("drugs", ["drugData"]),
    relatedDrugs() {
      let self = this;
      return this.pgxGeneSubs(self.geneData.gene.symbol)
        .filter(c => {
          return self.drugData(c);
        })
        .map(c => {
          return self.drugData(c)[self.$i18n.locale];
        })
        .sort()
        .join(", ");
    }
  },
  methods: {
    emitClose() {
      this.$emit("close");
    }
  }
};
</script>
<style lang="scss" scoped>
.gene-card {
  box-shadow: 0 5px 25px -18px #00000044, 0 -10px 20px inset #00000006;
  width: 100%;
  border: solid 2px #f3f3f3;

  /*Fix para a altura do ultimo item da lista*/
  max-height: 0px;
}
.left-border {
  border-left: solid 6px #4d2cb9;
  display: flex;
}
.v-card .v-card__actions {
  background: #ffffff00;
  box-shadow: 0 5px 30px -20px inset #310ab1;
}

#gene-result-dialog .text-h4 {
  font-size: 0.85rem !important;
}

.v-chip.grow {
  justify-content: center;
}

.lightbulb {
  animation-name: lightbulb;
  animation-duration: 2s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}
@keyframes lightbulb {
  from {
    filter: brightness(1) saturate(1);
  }
  to {
    filter: brightness(1.5) saturate(1.5);
  }
}
</style>
