<template>
  <v-container
    id="login"
    class="fill-height justify-center mt-n16"
    tag="section"
  >
    <fondo-brand></fondo-brand>
    <v-container id="logo">
      <v-row justify="center" class="mt-n16 z-index-99">
        <img src="@/assets/logo/Logo-Verde.png" height="50px" />
      </v-row>
      <v-row justify="center" class="mb-4 mb-sm-16">
        <h4 style="font-weight: 400;">
          {{ $t("Genetica") }} · {{ $t("FarmacoGenetica") }}
        </h4>
      </v-row>
    </v-container>
    <v-container class="mt-16">
      <v-row justify="center">
        <v-slide-y-transition appear>
          <base-material-card
            color="success"
            light
            max-width="100%"
            width="450"
            class="px-5 py-10"
          >
            <div class="text-center">
              <h1
                class="display-2 font-weight-bold mb-8"
                style="color: #1C093A;"
              >
                {{ $t("Iniciar sesion") }}
              </h1>
            </div>
            <validation-observer v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(login)">
                <v-card-text class="text-center pa-0">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="email"
                  >
                    <v-text-field
                      autocomplete="off"
                      rounded
                      outlined
                      v-model="email"
                      :label="$t('Direccion email') + '*'"
                      :error-messages="errors"
                      color="secondary"
                    />
                  </validation-provider>
                </v-card-text>
                <v-card-actions class="pa-0 justify-center">
                  <v-btn
                    default
                    rounded
                    color="primary"
                    type="submit"
                    class="font-weight-bold accent--text mr-0 ml-2"
                  >
                    {{ $t("Identificarse") }}
                  </v-btn>
                </v-card-actions>
              </form>
            </validation-observer>
          </base-material-card>
        </v-slide-y-transition>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import { SDKError, RPCError, ExtensionError } from "magic-sdk";
import { mapMutations } from "vuex";
import store from "@/store";
import FondoBrand from "@/components/mylogy/FondoBrand.vue";

export default {
  components: { FondoBrand },
  name: "PagesLogin",
  $_veeValidate: {
    validator: "new"
  },

  data: () => ({
    showPassword: false,
    email: ""
  }),
  created() {
    this.$store.commit("auth/SET_AUTH_LOADING", false);
  },
  mounted() {
    if (this.$route.query.email) {
      this.email = this.$route.query.email;
    }
    setTimeout(() => {
      this.$store.commit("auth/SET_AUTH_LOADING", false);
    }, 3000);
  },
  methods: {
    ...mapMutations({
      setUserData: "auth/SET_STRAPI_USER_DATA"
    }),
    login() {
      let self = this;

      this.$store
        .dispatch("auth/login", {
          email: this.email
        })
        .then(resp => {
          self.setUserData(resp.data);

          self.$user.set({ role: resp.data.type });
        })
        .catch(err => {
          if (err instanceof SDKError) {
            console.log(err);
            self.$store.commit("auth/SET_AUTH_LOADING", false);
          }
          if (err instanceof RPCError) {
            console.log(err);
            self.$store.commit("auth/SET_AUTH_LOADING", false);
          }
          if (err instanceof ExtensionError) {
            console.log(err);
            self.$store.commit("auth/SET_AUTH_LOADING", false);
          }

        });
    }
  },
  
};
</script>

<style lang="scss" scoped>
#logo {
  position: sticky;
  top: 80px;
}
.v-dialog.v-card {
  background: #ff00ff !important;
}
</style>
